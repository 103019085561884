import { OverviewConfig } from '@salary/common/component';

export function getMeldungenOverviewConfigs(): OverviewConfig[] {
  return [
    {
      caption: 'Entgeltersatzleistungen',
      iconName: 'price_change',
      description: getDescription('Entgeltersatzleistungen'),

      path: 'entgeltersatzleistungen',
    },
    {
      caption: 'Lohnsteueranmeldungen',
      iconName: 'credit_card_clock',
      description: getDescription('Lohnsteueranmeldungen'),
      path: 'lohnsteueranmeldungen',
    },
    {
      caption: 'Aufwendungsausgleichsgesetz (AAG)',
      iconName: 'badge',
      description:
        'Mit dieser Auswahl können Sie die Meldungen für das Aufwendungsausgleichsgesetz (AAG) ansehen.',
      path: 'aufwendungsausgleichsgesetz',
    },
    {
      caption: 'Sofortmeldungen',
      iconName: 'forward_10',
      description: getDescription('Sofortmeldungen'),
      path: 'sofortmeldungen',
    },
    {
      caption: 'Versicherungsnummern',
      iconName: 'format_list_numbered',
      description: getDescription('Versicherungsnummern'),
      path: 'versicherungsnummern',
    },
    {
      caption: 'Beitragsnachweise',
      iconName: 'how_to_vote',
      description: getDescription('Beitragsnachweise'),
      path: 'beitragsnachweise',
    },
    {
      caption: 'Arbeitsunfähigkeitsmeldung (eAU)',
      iconName: 'engineering',
      description: getDescription('Arbeitsunfähigkeitsmeldung (eAU)'),
      path: 'arbeitsunfaehigkeitsmeldungen',
    },
    {
      caption: 'DEÜV',
      iconName: 'connect_without_contact',
      description: getDescription('DEÜV'),
      path: 'deuevmeldungen',
    },
  ];
}
function getDescription(caption: string) {
  return `Mit dieser Auswahl können Sie die Meldungen für die ${caption} ansehen.`;
}
